import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../../layout/layout";
import config from "../../../data/SiteConfig";
import Img from "gatsby-image";

import {
    Container,
    Section,
    Icon
} from "bloomer";

export default class PurchaseCancel extends React.Component {
  render() {
    const template = (
      <Layout>
        <Helmet>
          <title>{`Shop | Purchase cancelled | ${config.siteTitle}`}</title>
        </Helmet>

        <Section>
          <Container>
            <hr />
            <h1 class="title is-1">Shop</h1>
            <hr />
            <p class="subtitle is-4">Purchase cancelled</p>
            <Link renderAs="button" class="button is-primary" to="/shop">
              <span>Continue browsing</span>{" "}
              <span class="icon">
                <i class="fas fa-chevron-right"></i>
              </span>
            </Link>
          </Container>
        </Section>
      </Layout>
    );
    return template;
  }
}
